/* Global */
html,
body {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  height: 100%;
  margin: 0;
}

/* Cricket */
table {
  margin-left: auto;
  margin-right: auto;
  /* border-collapse: separate; */
}

.table > :not(:first-child) {
  border-top: none;
}

/* Header */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px;
  margin-bottom: 30px;
}

.btnTable {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 50vh;
}

/* Create Player */
.playerNameHeader {
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
}

.playerInputRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Create Game */
.gameSelect {
  color: rgb(231, 241, 231);
  margin-top: 80px;
}

/* Baseball */
.inningCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.scoreboard {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}

/* Score Calculator */
.scoreCalculator {
  width: 50%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.scoreKeypad {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 5rem);
  grid-template-rows: repeat(4, 3rem);
  gap: 3px;
}

.scoreKeypadKillerInput {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 5rem);
  grid-template-rows: repeat(3, 3rem);
  gap: 3px;
}

.scoreKeypadCricket {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 5rem);
  grid-template-rows: repeat(3, 4rem);
  gap: 3px;
}

.undoRedo {
  display: grid;
  grid-template-columns: repeat(2, 5rem);
  justify-content: center;
}

.result {
  font-size: 30px;
}

/*-------*/

.navbar-brand {
  font-size: 2rem;
}

.form-control {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.outShotDisplay {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 904px) {
  .outShotDisplay {
    display: flex;
    flex-direction: column;
  }
}
.scoreCalculatorX01 {
  width: 50%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
}

@media only screen and (max-width: 904px) {
  .scoreCalculatorX01 {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-items: flex-start;
  }
}

.outShotContainer {
  flex-basis: 20%;
  margin-right: 5%;
  margin-left: 10%;
  justify-content: center;
  margin-top: 5%;
}

@media only screen and (max-width: 904px) {
  .outShotContainer {
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    background-color: #363537;
    opacity: 0.8;
    border-radius: 10px;
    padding-right: 6px;
    padding-left: 6px;
  }
}
@media only screen and (max-width: 400px) {
  .outShotContainer {
    margin-left: auto;
    margin-right: auto;
    color: #e2e2e2;
  }
}

.undoRedoX01 {
  display: grid;
  grid-template-columns: repeat(2, 5rem);
  margin-left: 2.8rem;
}

@media only screen and (max-width: 904px) {
  .undoRedoX01 {
    margin-left: 2.8rem;
    margin-right: auto;
    align-items: center;
  }
}

.scoreKeypadX01 {
  display: grid;
  grid-template-columns: repeat(3, 5rem);
  grid-template-rows: repeat(4, 3rem);
  gap: 3px;
}

.playerScoreDisplay {
  width: 30vw;
  font-size: 1.5rem;
}

.playerScoreTextTotal {
  text-align: center;
  padding-left: 2px;
}
.playerScoreTextScore {
  text-align: left;
}

.background {
  margin: 0 0;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  min-width: none;
}

@media only screen and (max-width: 904px) {
  .background {
    background-position: right 15% bottom 20%;
    height: 100vh;
    background-size: 125%;
  }
}

@media only screen and (max-width: 400px) {
  .background {
    height: 100vh;
    background-size: 170%;
  }
}

.winnerAlert {
  font-weight: bold;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  opacity: 0.7;
}
